import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchBlogslist = createAsyncThunk(
    'blogs/fetchBlogslist',
    async () => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/blogs`)
        return res.data
    }
)

export const fetchBlog = createAsyncThunk(
    'blogs/fetchBlog',
    async (blogId) => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/blogs/${blogId}`)
        return res.data
    }
)

export const blogsSlice = createSlice({
    name: 'blogs',
    initialState: {
        isShow: false,
        modalName: '',
        listBlogs: [],
        blog:[]
    },
    reducers: {},
    extraReducers: {
        [fetchBlogslist.fulfilled]: (state, action) => {
            state.listBlogs = action.payload
        },
        [fetchBlog.fulfilled]: (state, action) => {
            state.blog = action.payload
        },
    },
})