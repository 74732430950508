import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'

export const deleteBlog = createAsyncThunk('admin/deleteBlog', async (blogId, { getState }) => {
  const res = await axios({
    method: 'DELETE',
    url: `${process.env.REACT_APP_API_URL}/admin/blogs/${blogId}`,
    headers: {
      Authorization: 'Bearer ' + _.get(getState().auth, 'token', ''),
    },
  })
  return _.get(res, 'data', null)
})

export const adminFetchBlogs = createAsyncThunk('university/adminFetchBlogs ', async (__unused, { getState }) => {
  const res = await axios({
    method:'GET',
    url:`${process.env.REACT_APP_API_URL}/admin/blogs`,
    headers: {
      Authorization: 'Bearer ' + _.get(getState().auth, 'token', ''),
    }
  })

  return _.get(res, 'data', null)
})

export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    blogs: [],
    loading: 'idle',
    currentRequestId: undefined,
    error: null,
  },
  reducers: {
    applyFilter: (state) => state,
  },
  extraReducers: {
    [adminFetchBlogs.fulfilled]: (state, action) => {
      state.blogs = action.payload
    },
    [deleteBlog.fulfilled]: (state, action) => {
      state.blogs = state.blogs.filter(blog => blog.id !== action.payload.id)
    },
  },
})
