export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_ERROR = "LOGIN_ERROR"

export const LOGOUT_REQUEST = "LOGOUT_REQUEST"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_ERROR = "LOGOUT_ERROR"

export const FOGOT_PASSWORD_REQUEST = "FOGOT_PASSWORD_REQUEST"
export const FOGOT_PASSWORD_SUCCESS = "FOGOT_PASSWORD_SUCCESS"
export const FOGOT_PASSWORD_ERROR = "FOGOT_PASSWORD_ERROR"