import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchUniversity = createAsyncThunk(
  'university/fetchUniversity',
  async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/universities`)
    return res.data
  }
)

export const fetchSingleUniversity = createAsyncThunk(
  'university/fetchSingleUniversity',
  async (universityId) => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/universities/${universityId}`)
    return res.data
  }
)

export const universitySlice = createSlice({
  name: 'university',
  initialState: {
    entities: [],
    loading: 'idle',
    currentRequestId: undefined,
    current: {
      name: '',
      courses: [],
      images: [],
      reviews: [],
    },
    error: null,
  },
  reducers: {
    applyFilter: (state) => state,
    increment: (state) => state + 1,
    decrement: (state) => state - 1,
    // setCurrent: (state, action) => {
    //   const current = state.entities.find(e => e.id === action.payload.universityId)
    //   if(current) {
    //     state.current = current
    //     return
    //   }
    // }
    // fetchUniversity: async () => {
    //   const res = await axios.get(`${process.env.react_app_api_url}/universities`)
    //   return res.data
    // },
  },
  extraReducers: {
    [fetchUniversity.fulfilled]: (state, action) => {
      state.entities = action.payload
    },
    [fetchSingleUniversity.fulfilled]: (state, action) => {
      state.current = action.payload
    },
  },
})