import React, { useEffect, useRef, useState } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'
import style from './Navbar.module.scss'
import { modalSlice } from '../../redux/Modal.slice'
import { useDispatch, useSelector } from 'react-redux'
import ProfilePicture from '../Profile/ProfilePicture'
import ProfilePopup from '../Profile/ProfilePopup'
import Fade from 'react-reveal/Fade'
import { logout } from '../../redux/auth/Auth.actions'
import _ from 'lodash'

const MENU_LINK = [
  ['Home', '/'],
  ['University', '/university'],
  ['Courses', '/courses'],
  ['Blog', '/blog'],
  ['About Us', '/about-us'],
]

const ProfilePicComponent = ({ profilePath = '', firstName = '', small = true }) => {
  return (
    <div className={`${style.container} ${style.normal} ${style.profilePath}`}>
      {profilePath ? (
        <img src={profilePath} alt='Avatar' />
      ) : (
        <div className={style.avatar}>{firstName?.[0]}</div>
      )}
    </div>
  )
}

const Navbar = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const auth = useSelector((state) => state.auth)
  const [profilePicture, setProfilePicture] = useState()
  const [showShadow, setShowShadow] = useState(false)
  const [showProfilePopup, setShowProfilePopup] = useState(false)

  const ref = useRef()

  const handleLogout = () => {
    dispatch(logout())
    history.push('/')
  }

  const checkCurrentPath = () => {
    const currentPath = window.location.pathname
    if (_.includes(currentPath, '/setting')) {
      dispatch(modalSlice.actions.showContactUs())
    }
    return document.querySelector('#contact-us')?.scrollIntoView?.({
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      if (window.pageYOffset > 0) {
        setShowShadow(true)
      } else {
        setShowShadow(false)
      }
    })
  }, [auth])

  const [toggleMenu, setToggleMenu] = useState(false)

  function toggle() {
    setToggleMenu(!toggleMenu)
  }

  useEffect(() => {
    const unlisten = history.listen(() => setShowProfilePopup(false)) // Scroll to top!
    return () => {
      unlisten && unlisten()
    }
  }, [history])

  useEffect(() => {
    if (auth?.user?.profilePath) {
      setProfilePicture(auth?.user?.profilePath)
    }
  }, [auth])

  return (
    <header className={`${style.siteHeader} ${showShadow ? style.floatingNav : ''}`}>
      <div className={`${style.siteHeader__wrapper}`}>
        <div className={style.siteHeader__start}>
          <a href='/' className={style.brand}>
            <img src={require('./assets/brand.png')} alt='logo' />
          </a>
        </div>
        <div className={style.siteHeader__middle}>
          <ul className={style.subNav}>
            {MENU_LINK.map(([title, link], index) => (
              <li key={index}>
                <NavLink exact to={link} activeClassName={style.active} className={`${style.tab}`}>
                  {title}
                </NavLink>
              </li>
            ))}
            <li>
              <div onClick={() => checkCurrentPath()} className={style.tab}>
                Contact Us
              </div>
            </li>
          </ul>
        </div>
        {auth?.initlized ? (
          <div className={style.siteHeader__end}>
            {auth?.user ? (
              <>
                <ProfilePicture
                  onClick={() => setShowProfilePopup(true)}
                  profilePath={auth?.user?.profilePath}
                  firstName={auth?.user?.firstName}
                />
                <ProfilePopup
                  onClose={() => setShowProfilePopup(false)}
                  show={showProfilePopup}
                  onHide={() => setShowProfilePopup(false)}
                  profilePicPopup={auth?.user.profilePath}
                />
              </>
            ) : (
              <>
                <button
                  type='button'
                  className={`${style.button} ${style.login}`}
                  onClick={() => dispatch(modalSlice.actions.showLogin())}
                >
                  Login
                </button>
                <button
                  type='button'
                  className={`${style.button} ${style.joinUs} ${style.ml1}`}
                  onClick={() => dispatch(modalSlice.actions.showSignup())}
                >
                  Join us
                </button>
              </>
            )}
          </div>
        ) : null}
      </div>
      {/* RESPONSIVE */}
      <div className={style.siteHeader__mobile}>
        <div className={style.siteHeader__start__mobile}>
          <a href='/' className={style.brand}>
            <img src={require('./assets/brand.png')} alt='logo' />
          </a>
        </div>

        <img
          onClick={toggle}
          src={require('./assets/Menu-left.png')}
          className={style.openIcon}
          alt='open-icon'
        />
        <Fade duration={800}>
          {toggleMenu && (
            <div className={`${style.bgMenu}`}>
              <div className={style.coverItemMenu}>
                {auth?.initlized && (
                  <div className={style.siteHeader__endResponsive}>
                    {auth?.user ? (
                      <>
                        <ProfilePicComponent profilePath={profilePicture} firstName={auth?.user?.firstName} />
                        <div className={style.userName}>
                          <span>{auth.user.firstName}</span> <span>{auth.user.lastName}</span>
                        </div>
                        <Link to='/setting/basic-info' className={style.userSetting} onClick={toggle}>
                          Account Setting
                        </Link>
                        <ProfilePopup
                          ref={ref}
                          show={showProfilePopup}
                          onHide={() => setShowProfilePopup(false)}
                        />
                      </>
                    ) : (
                      <>
                        <button
                          type='button'
                          className={style.buttonLogin}
                          onClick={() => dispatch(modalSlice.actions.showLogin())}
                        >
                          Login
                        </button>
                        <button
                          type='button'
                          className={`${style.button} ${style.joinUs} ${style.ml1}`}
                          onClick={() => dispatch(modalSlice.actions.showSignup())}
                        >
                          Join us
                        </button>
                      </>
                    )}
                  </div>
                )}
                <div className={style.line}></div>
                <div className={style.siteHeader__middleResponsive}>
                  <ul className={style.subNav}>
                    {MENU_LINK.map(([title, link], index) => (
                      <li>
                        <NavLink
                          key={index}
                          exact
                          to={link}
                          onClick={toggle}
                          activeClassName={style.active}
                          className={`${style.tab}`}
                        >
                          {title}
                        </NavLink>
                      </li>
                    ))}
                    <li>
                      <div
                        className={style.tab}
                        onClick={() => {
                          setToggleMenu(false)
                          checkCurrentPath()
                        }}
                      >
                        Contact Us
                      </div>
                    </li>
                    {auth.user ? (
                      <li>
                        <div
                          onClick={() => {
                            handleLogout()
                            toggle()
                          }}
                          className={style.tab}
                        >
                          Log out
                        </div>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </div>
              <img
                onClick={toggle}
                className={`${style.closeIcon}`}
                src={require('./assets/Close.svg')}
                alt='close-icon'
              />
            </div>
          )}
        </Fade>
      </div>
    </header>
  )
}

export default Navbar
