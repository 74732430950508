import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

const LoggedInRoute = ({ children }) => {
  const isLogin = useSelector((state) => state.auth?.user)
  const history = useHistory()
  if(!isLogin) {
    history.push('/')
  }

  return children
}

export default LoggedInRoute