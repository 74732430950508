import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchlesson = createAsyncThunk(
    'lesson/fetchlesson',
    async (courseId) => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/lesson/${courseId}`)
        return res.data
    }
)

export const lessonSlice = createSlice({
    name: 'lesson',
    initialState: {
        isShow: false,
        modalName: '',
        lesson:[]
    },
    reducers: {},
    extraReducers: {
        [fetchlesson.fulfilled]: (state, action) => {
            state.lesson = action.payload
        },
    },
})