import { combineReducers } from 'redux'

import { appReducer } from '../App/App.reducer'
import { authReducer } from './auth/Auth.reducer'
// import { modalReducer } from "./reducer_modal/Modal.reducer"

// import { universityReducer } from "../reducer_university/University.reducer"
import { universitySlice } from './University.slice'
import { adminSlice } from './Admin.slice'
import { modalSlice } from './Modal.slice'

import { blogsSlice } from './Blog.slice'
import { reviewsSlice } from './Review.slice'
import { bannersSlice } from './Banner.slice'
import { lessonSlice } from './lesson.slice'
import { billingSlice } from './billing.slice'
import { courseSlice } from './Course.slice'
import { applicationSlice } from './Application.slice'
import { countrySlice } from './country.slice'
import { configSlice } from './config.slice'

export const rootReducers = combineReducers({
  app: appReducer,
  auth: authReducer,
  // modal: modalReducer,
  lesson: lessonSlice.reducer,
  billing: billingSlice.reducer,
  blogs: blogsSlice.reducer,
  reviews: reviewsSlice.reducer,
  admin: adminSlice.reducer,
  modal: modalSlice.reducer,
  university: universitySlice.reducer,
  banner: bannersSlice.reducer,
  course: courseSlice.reducer,
  application: applicationSlice.reducer,
  country: countrySlice.reducer,
  config: configSlice.reducer,
})
