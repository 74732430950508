// import * as ActionTypes from './App.actions'

const initialState = {}

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}
