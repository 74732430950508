import axios from 'axios'

const login = (email, password) => {
  return axios.post(`${process.env.REACT_APP_API_URL}/login`, { email, password }).then((res) => {
    const user = res.data
    // localStorage.setItem('user', JSON.stringify(user))
    return user
  })
}

const create = (fullName, email, password) => {}

const logout = () => {
  localStorage.removeItem('user')
  return Promise.resolve(true)
}

const loginCheck = () => {
  const raw_user = localStorage.getItem('user')
  if (raw_user) {
    try {
      return JSON.parse(raw_user)
    } catch (error) {
      return null
    }
  }
  return null
}

const loginGoogle = async (token, profileImage) => {
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/loginWithGoogle`, {
      token: token,
      profileImg: profileImage,
    })
    .then(async (res) => {
      if (res.data) {
        // localStorage.setItem('user', JSON.stringify(res.data))
        return res.data
        // dispatch({ type: SIGN_IN_SUCCESS, payload: { ...res.data, token: token, isGoogleLogin: true } })
        //   history.push('/dashboard')
      }
    })
    .catch((error) => {
      //   dispatch({
      //     type: SIGN_IN_FAILURE,
      //     payload: { signInError: 404 },
      //   })
      console.log('ERROR googleLogin error >>>', error)
    })
}

const loginFacebook = async (token, profileImageFB) => {
  return await axios
    .post(`${process.env.REACT_APP_API_URL}/loginWithFacebook`, {
      token: token,
      profileImg: profileImageFB,
    })
    .then(async (res) => {
      console.log('Facebook Login SUCCESS facebookLogin', res)

      if (res.data) {
        console.log(res.data)
        // localStorage.setItem('user', JSON.stringify(res.data))
        return res.data
      }
    })
    .catch((error) => {
      console.log('Facebook Login ERROR', error)
    })
}


export const authService = {
  login,
  loginGoogle,
  loginFacebook,
  logout,
  create,
  loginCheck,
}
