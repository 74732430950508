import * as ActionTypes from './Auth.constants'

const initialState = { initlized: true, loading: false, user: null, error: null }

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case '@@INIT':
      return {
        ...state,
        initlized: true,
      }
    case ActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      }
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.user,
        status: null,
        error: null,
        message: null,
      }
    case ActionTypes.LOGIN_ERROR:
      return {
        ...initialState,
        loading: false,
        status: action.status,
        error: action.error,
        message: action.message,
      }

    case ActionTypes.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
        user: null,
      }
    case ActionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        user: null,
        loading: false,
      }
    case ActionTypes.LOGOUT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      }
    default:
      return state
  }
}
