import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchConfigs = createAsyncThunk('config/fetchConfigs', async () => {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/admin/config`)
  return res.data
})

export const configSlice = createSlice({
  name: 'configs',
  initialState: {
    configs: [],
    loading: 'idle',
    currentRequestId: undefined,
    error: null,
  },
  extraReducers: {
    [fetchConfigs.fulfilled]: (state, action) => {
      state.configs = action.payload
    },
  },
})
