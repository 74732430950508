import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchbilling = createAsyncThunk(
    'bill/fetchbilling',
    async (userId) => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/billing/${userId}`)
        return res.data
    }
)

export const billingSlice = createSlice({
    name: 'bill',
    initialState: {
        isShow: false,
        modalName: '',
        billing:[]
    },
    reducers: {},
    extraReducers: {
        [fetchbilling.fulfilled]: (state, action) => {
            state.bill = action.payload
        },
    },
})