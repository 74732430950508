import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchCourse = createAsyncThunk(
    'courses/fetchCourse',
    async (courseId, {getState}) => {
        const {data} = await axios({
            url: `${process.env.REACT_APP_API_URL}/courses/${courseId}`,
            method: 'GET',
            headers: {
                  Authorization: getState().auth.user?.token || '',
                }
              ,
          })
        return data
    }
)

export const fetchCourseList = createAsyncThunk(
    'courses/fetchCourseList',
    async () => {
        const {data} = await axios({
            url: `${process.env.REACT_APP_API_URL}/courses`,
            method: 'GET',
          })
        return data
    }
)

export const courseSlice = createSlice({
    name: 'courses',
    initialState: {
        isShow: false,
        modalName: '',
        course: [],
        courseList: []
    },
    reducers: {
        clearCourse: (state, action) => {
           state.course = []
        }
    },
    extraReducers: {
        [fetchCourse.fulfilled]: (state, action) => {
            state.course = action.payload
        },
        [fetchCourseList.fulfilled]: (state, action) => {
            state.courseList = action.payload
        },
    },
})

export const {clearCourse} = courseSlice.actions