import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'

export const fetchReviews = createAsyncThunk('reviews/fetchReviews', async (__unused, { getState }) => {
  const res = await axios({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}/reviews`,
  })
  return _.get(res, 'data', null)
})

export const reviewsSlice = createSlice({
  name: 'reviews',
  initialState: {
    isShow: false,
    modalName: '',
    listReviews: [],
  },
  reducers: {},
  extraReducers: {
    [fetchReviews.fulfilled]: (state, action) => {
      state.listReviews = action.payload
    },
  },
})
