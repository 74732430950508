import { authService } from './Auth.services'
import * as ActionTypes from './Auth.constants'
import { modalSlice } from '../Modal.slice'
import axios from 'axios'

export const login = (email, password) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.LOGIN_REQUEST })
    axios
      .post(`${process.env.REACT_APP_API_URL}/login`, { email, password })
      .then((res) => {
        const user = res.data
        dispatch(success(user))
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          dispatch({ type: ActionTypes.LOGIN_ERROR, status: 400, message: 'Email หรือ password ไม่ถูกต้อง' })
        }
        if (error?.response?.status === 500) {
          dispatch({ type: ActionTypes.LOGIN_ERROR, status: 500, message: 'ระบบมีปัญหากรุณาติดต่อ Admin' })
        }
      })
  }

  function success(user) {
    return { type: ActionTypes.LOGIN_SUCCESS, user }
  }
}

export const registerSuccess = (user) => (dispatch) => {
  dispatch({ type: ActionTypes.LOGIN_SUCCESS, user })
}

export const googleLogin = (token, profileImage) => {
  return (dispatch) => {
    dispatch(request())

    authService.loginGoogle(token, profileImage).then(
      (user) => {
        dispatch(modalSlice.actions.hide())
        dispatch(success(user))
        // history.push("/");
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(alertActions.error(error));
      }
    )
  }

  function request() {
    return { type: ActionTypes.LOGIN_REQUEST }
  }
  function success(user) {
    return { type: ActionTypes.LOGIN_SUCCESS, user }
  }
  function failure(error) {
    return { type: ActionTypes.LOGIN_ERROR, error }
  }
}

export const facebookLogin = (token, profileImageFB) => {
  return (dispatch) => {
    dispatch(request())

    authService.loginFacebook(token, profileImageFB).then(
      (user, error) => {
        dispatch(modalSlice.actions.hide())
        dispatch(success(user))
        // history.push("/");
      },
      (error) => {
        dispatch(failure(error))
        // dispatch(alertActions.error(error));
      }
    )
  }

  function request() {
    return { type: ActionTypes.LOGIN_REQUEST }
  }
  function success(user) {
    return { type: ActionTypes.LOGIN_SUCCESS, user }
  }
  function failure(error) {
    return { type: ActionTypes.LOGIN_ERROR, error }
  }
}

export const logout = () => {
  return (dispatch) => {
    dispatch(request())

    authService.logout().then(
      (user) => {
        dispatch(success())
      },
      (error) => {
        dispatch(failure())
      }
    )
  }

  function request() {
    return { type: ActionTypes.LOGOUT_REQUEST }
  }
  function success() {
    return { type: ActionTypes.LOGOUT_SUCCESS }
  }
  function failure() {
    return { type: ActionTypes.LOGOUT_ERROR }
  }
}

export const loginCheck = () => (dispatch, getState) => {
  // NOTE: token is stored in Redux not localStorage
  const state = getState()
  const token = state?.auth?.user?.token
  if (token) {
    axios({
      url: `${process.env.REACT_APP_API_URL}/users/`,
      headers: token ? { Authorization: `Bearer ${token}` } : {},
      method: 'GET',
    })
      .then((response) => {
        console.log(response.data)
        dispatch({ type: ActionTypes.LOGIN_SUCCESS, user: { ...response.data, token } })
      })
      .catch((err) => {
        dispatch({ type: ActionTypes.LOGIN_ERROR })
      })
  }
}
