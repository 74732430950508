import React from 'react'

import style from './ProfilePicture.module.scss'

// import ProfilePic from "./assets/Profile.png"

const ProfilePicture = ({ profilePath = '', firstName = '', small = true, onClick }) => {
  return (
    <div className={`${style.container} ${small ? style.small : style.normal}`} onClick={() => onClick()}>
      {profilePath ? (
        <img src={profilePath} alt='Avatar' />
      ) : (
        <div className={style.avatar}>{firstName?.[0]}</div>
      )}
    </div>
  )
}

export default ProfilePicture
