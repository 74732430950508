import React, { Suspense, lazy, useEffect } from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import AppHelmet from './App.helmet'
import { useDispatch, useSelector } from 'react-redux'
import { loginCheck } from '../redux/auth/Auth.actions'
import { hotjar } from 'react-hotjar'

import history from './history'
import LoggedInRoute from './LoggedInRoute'

import './App.scss'
import $Script from 'scriptjs'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Cookies from '../components/Cookies'
const Blog = lazy(() => import('../Blog'))
const BlogOverview = lazy(() => import('../BlogOverview'))
const UniversityListing = lazy(() => import('../University/UniversityListing'))
// const UniversitySingle = lazy(() => import('../UniversitySingle/UniversitySingle'))
const UniversityDetail = lazy(() => import('../University/UniversityDetail'))
const TermCondition = lazy(() => import('../Policy/TermCondition'))
const PrivacyPolicy = lazy(() => import('../Policy/PrivacyPolicy'))
const CentralizedModal = lazy(() => import('../components/Auth/CentralizedModal'))
const Course = lazy(() => import('../Course'))
const CourseSingle = lazy(() => import('../CourseSingle'))
const About = lazy(() => import('../AboutUs'))
const HomeTon = lazy(() => import('../HomeTon'))
const BasicInfo = lazy(() => import('../UserSetting/Setting/BasicInfo'))
const ResetPass = lazy(() => import('../UserSetting/Setting/ResetPass'))
const BookmarkUni = lazy(() => import('../UserSetting/Bookmark/University'))
const BookmarkCoures = lazy(() => import('../UserSetting/Bookmark/Course'))
const TrackingProcess = lazy(() => import('../UserSetting/TrackingProcess'))
const MyCourse = lazy(() => import('../UserSetting/MyCourse'))

function App() {
  const dispatch = useDispatch()
  const isShow = useSelector((state) => state.modal.isShow)
  const getAccepted = localStorage.getItem('accept')
  useEffect(() => {
    const unlisten = history.listen(() => window.scrollTo(0, 0)) // Scroll to top!
    return () => {
      unlisten && unlisten()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  useEffect(() => {
    dispatch(loginCheck())
    // dispatch(modalSlice.actions.showTransferPayment())

    $Script('https://cdn.omise.co/omise.js', () => {
      // Init Omise
      window.Omise.setPublicKey(process.env.REACT_APP_OMISE_PUBLIC_KEY)
    })

    // eslint-disable-next-line
  }, [])

  // Hotjar tracking code
  useEffect(() => {
    if (process.env.REACT_APP_ENV_NAME === 'production') {
      hotjar.initialize(2864355, 6)
    }
  }, [])

  return (
    <HelmetProvider>
      <Helmet script={[{ src: 'https://cdn.omise.co/omise.js', type: 'text/javascript' }]} />
      <AppHelmet />
      <Router history={history}>
        <Suspense fallback={<span>Loading...</span>}>
          <Switch>
            <Route path='/'>
              <Navbar />
              {/* <Cookies /> */}
              {isShow && <CentralizedModal />}
              <Suspense fallback={<span>Loading...</span>}>
                <Switch>
                  <Route exact path='/' component={HomeTon} />

                  <LoggedInRoute path='/setting'>
                    <Route exact path='/setting' render={() => <Redirect to='/setting/my-course' />} />
                    <Route exact path='/setting/basic-info' component={BasicInfo} />
                    <Route exact path='/setting/reset-password' component={ResetPass} />

                    <Route exact path='/setting/tracking-process' component={TrackingProcess} />
                    <Route exact path='/setting/tracking-process/:status' component={TrackingProcess} />
                    <Route exact path='/setting/my-course' component={MyCourse} />

                    <Route exact path='/setting/bookmark-university' component={BookmarkUni} />
                    <Route exact path='/setting/bookmark-course' component={BookmarkCoures} />
                  </LoggedInRoute>

                  <Route exact path='/term-condition' component={TermCondition} />
                  <Route exact path='/privacy-policy' component={PrivacyPolicy} />

                  {/* <Route path='/university/:universityId' component={UniversitySingle} /> */}
                  <Route path='/university/:universityName' component={UniversityDetail} />
                  <Route path='/university' component={UniversityListing} />

                  <Route exact path='/courses' component={Course} />
                  <Route path='/courses/:courseId/lessons/:lessonId' component={CourseSingle} />
                  <Route path='/courses/:courseId/question/:questionId' component={CourseSingle} />
                  <Route path='/courses/:courseId' component={CourseSingle} />
                  <Route exact path='/about-us' component={About} />
                  <Route exact path='/blog' component={Blog} />
                  <Route exact path='/blog-overview/:blogId' component={BlogOverview} />
                  <Redirect to='/' />
                </Switch>
              </Suspense>
              {getAccepted ? null : <Cookies />}

              <Footer />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    </HelmetProvider>
  )
}

export default App
