import React, { useState } from 'react'
import style from './Cookies.module.scss'

const Cookies = () => {
  const [accept, setAccept] = useState(false)
  const accepted = () => {
    if (accept !== true) {
      setAccept(true)
      localStorage.setItem('accept', true)
    }
  }
  return (
    <div className={`${style.backGround} ${accept && style.accepted}`}>
      <div className={style.wrap}>
        <div className={style.content}>
          We use cookies to optimize site functionality and give you the best possible experience while
          browsing our site. If you are fine with this and accept all cookies just click "Accept" button. You
          can also review our
          <a href='/privacy-policy' className={style.policy}>
            Privacy policy.
          </a>
        </div>
        <button
          className={`${style.button}`}
          onClick={() => {
            accepted()
          }}
        >
          Accept
        </button>
      </div>
    </div>
  )
}
export default Cookies
