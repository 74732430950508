import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchBanner = createAsyncThunk(
    'banners/fetchBanner',
    async () => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/banners?page=Home`)
        return res.data
    }
)


export const bannersSlice = createSlice({
    name: 'banners',
    initialState: {
        isShow: false,
        modalName: '',
        banners: [],
    },
    reducers: {},
    extraReducers: {
        [fetchBanner.fulfilled]: (state, action) => {
            state.banners = action.payload
        },
    },
})