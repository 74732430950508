import { createSlice } from '@reduxjs/toolkit'

export const modalSlice = createSlice({
  name: 'modal',
  initialState: {
    isShow: false,
    modalName: '',
  },

  reducers: {
    showGallery: (state, action) => {
      state.isShow = true
      state.modalName = 'gallery'
      state.images = action.payload
    },
    showReview: (state, action) => {
      state.isShow = true
      state.modalName = 'review'
      state.universityId = action.payload.universityId
      state.courseId = action.payload.courseId
    },
    showLogin: (state) => {
      state.isShow = true
      state.modalName = 'login'
    },
    showSignup: (state) => {
      state.isShow = true
      state.modalName = 'signup'
    },
    showForgotPassword: (state) => {
      state.isShow = true
      state.modalName = 'forgotPassword'
    },
    hide: (state) => {
      state.isShow = false
      state.modalName = ''
    },
    showUniReview: (state, action) => {
      state.isShow = true
      state.modalName = 'uniReview'
      state.uniReview = action.payload
    },
    showStudentReview: (state, action) => {
      state.isShow = true
      state.modalName = 'studentReview'
      state.studentReview = action.payload
    },
    setCourseId: (state, action) => {
      state.courseId = action.payload
      // action.payload
    },
    showCardPayment: (state, action) => {
      state.isShow = true
      state.modalName = 'cardPayment'
    },
    showTransferPayment: (state, action) => {
      state.isShow = true
      state.modalName = 'transferPayment'
    },
    showPaymentSuccess: (state) => {
      state.isShow = true
      state.modalName = 'paymentSuccess'
    },
    showPaymentFailure: (state) => {
      state.isShow = true
      state.modalName = 'paymentFailure'
    },
    showFilterCourse: (state) => {
      state.isShow = true
      state.modalName = 'filterCourse'
    },
    showQuizBox: (state, action) => {
      state.isShow = true
      state.modalName = 'quizBox'
      state.questionId = action.payload.questionId
    },
    showContactUs: (state) => {
      state.isShow = true
      state.modalName = 'contactUs'
    },
    // setCheckQuestion: (state, action) => {
    //   state.checkQuestion = action.payload
    // },
    // setAnswer: (state, action) => {
    //   state.answer = action.payload
    // },
  },
})
