import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/auth/Auth.actions'
import { Link, useHistory } from 'react-router-dom'
import useOnclickOutside from 'react-cool-onclickoutside'

import style from './ProfilePopup.module.scss'
import navbarStyle from '../Navbar/Navbar.module.scss'


const ProfilePicComponent = ({ profilePath = '', firstName = '', small = true }) => {
  return (
    <div className={`${style.wrapper} ${style.normal}`}>
      {profilePath ? (
        <img src={profilePath} alt='Avatar' />
      ) : (
          <div className={style.avatar}>{firstName?.[0]}</div>
        )}
    </div>
  )
}

const ProfilePopup = ({ show, profilePicPopup, onClose = () => { } }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const auth = useSelector((state) => state.auth)
  const { user } = auth
  const ref = useOnclickOutside(
    () => {
      onClose()
    },
    { ignoreClass: navbarStyle.siteHeader }
  )
  const handleLogout = () => {
    dispatch(logout())
    history.push('/')
  }
  return (
    <div ref={ref} className={`${style.container} ${show ? style.show : ''}`}>
      <div className={style.popup}>
        <div className={style.profileContainer}>
          <Link to='/setting/basic-info'>
            <ProfilePicComponent profilePath={profilePicPopup} firstName={auth?.user?.firstName} />
          </Link>
          <h4>Hello, {`${user?.firstName || ''} ${user?.lastName || ''}`}</h4>
          <ul className={style.list}>
            <Link to='/setting/tracking-process'>Tracking Process</Link>
            <Link to='/setting/my-course'>My course</Link>
            <Link to='/setting/bookmark-university'>Bookmark</Link>
            <Link to='/setting/basic-info'>Setting</Link>
            <li onClick={() => handleLogout()}>Log out</li>
          </ul>
        </div>
      </div>
    </div>
  )
}


export default ProfilePopup
