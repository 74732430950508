import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchCountries = createAsyncThunk('countries/fetchCountries', async () => {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/countries`)
  return res.data
})

export const countrySlice = createSlice({
  name: 'university',
  initialState: {
    counties: [],
    loading: 'idle',
    currentRequestId: undefined,
    error: null,
  },
  extraReducers: {
    [fetchCountries.fulfilled]: (state, action) => {
      state.entities = action.payload
    },
  },
})
